import { useState, useEffect } from "react";
import { motion, useAnimation } from "framer-motion";
import { useAudioRecorder } from "react-audio-voice-recorder";

import micIcon from "../images/microphone.png";

const RecorderColor = `#C35D23`;

const outerCircleVariants = {
  circle: {
    transform: "scale(1)",
    opacity: 0.5,
    boxShadow: `0px 0px 0px 3px ${RecorderColor}`,
  },
  largeCircle: {
    transform: "scale(1.2)",
    opacity: 1,
    boxShadow: `0px 0px 0px 3px ${RecorderColor}`,
  },
  pulseIn: {
    transform: "scale(1.2)",
    opacity: 1,
    boxShadow: `0px 0px 0px 5px ${RecorderColor}`,
  },
  pulseOut: {
    transform: "scale(1.2)",
    opacity: 1,
    boxShadow: `0px 0px 0px 3px ${RecorderColor}`,
  },
};

const innerCircleVariants = {
  circle: {
    transform: "scale(1)",
    borderRadius: "100%",
  },
  square: {
    transform: "scale(0.8)",
    borderRadius: "30%",
  },
  invisible: {
    transform: "scale(0)",
    borderRadius: "100%",
  },
};

export const RecordButton = ({ toggleHandler }) => {
  // --------------- States ---------------
  const [hover, setHover] = useState(false);
  const innerCircleAnimation = useAnimation();
  const outerCircleAnimation = useAnimation();
  const recorderControls = useAudioRecorder(
    {
      noiseSuppression: true,
      echoCancellation: true,
    },
    (err) => console.table(err) // onNotAllowedOrFound
  );
  const [isRecording, setRecording] = useState(false);

  // --------------- Effects ---------------
  useEffect(() => {
    (async () => {
      if (hover || isRecording) {
        await outerCircleAnimation.start("largeCircle");
      } else {
        await outerCircleAnimation.start("circle");
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hover]);

  useEffect(() => {
    (async () => {
      if (isRecording) {
        await innerCircleAnimation.start("square");
        await innerCircleAnimation.start("invisible");

        await outerCircleAnimation.start(["pulseOut", "pulseIn"], {
          repeat: Infinity,
          repeatType: "mirror",
        });
      } else {
        await outerCircleAnimation.start("circle");
        await innerCircleAnimation.start("circle");
      }
    })();
  }, [isRecording]);

  useEffect(() => {
    const recBlob = recorderControls.recordingBlob;
    if (recBlob) {
      toggleHandler(recBlob);
    }
  }, [recorderControls.recordingBlob]);

  // --------------- Functions ---------------
  const recordToggler = async () => {
    setRecording(!isRecording);
    if (recorderControls.isRecording) {
      recorderControls.stopRecording();
    } else {
      recorderControls.startRecording();
    }
  };

  return (
    <motion.div
      style={styles.container}
      onClick={recordToggler}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <motion.div
        initial="circle"
        animate={outerCircleAnimation}
        variants={outerCircleVariants}
        style={{ ...styles.circle, ...styles.outerCircle }}
      />
      <motion.div
        initial="circle"
        animate={innerCircleAnimation}
        variants={innerCircleVariants}
        style={{
          ...styles.circle,
          ...styles.innerCircle,
        }}
      >
        <img
          src={micIcon}
          style={{
            width: "30px",
            marginTop: "5px",
          }}
        />
      </motion.div>
    </motion.div>
  );
};

const styles = {
  container: {
    cursor: "pointer",
    position: "relative",
    display: "inline-flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "22px",
    background: "rgba(245, 245, 245, 1)",
    marginLeft: "5px",
    // boxShadow: "0px 21px 27px -10px #C35D23",
  },
  circle: {
    position: "absolute",
  },
  outerCircle: {
    width: "100%",
    height: "100%",
    overflow: "hidden",
    borderRadius: 9999,
  },
  innerCircle: {
    width: "95%",
    height: "95%",
    overflow: "hidden",
    background: "linear-gradient(-15deg, #C35D23 46.9%, #C35D23 85.42%)",
    textAlign: "center",
  },
};
