import { useEffect, useRef, useState } from "react";
import { PauseCircleOutline, PlayCircleOutline } from "@mui/icons-material";
import { Grid, IconButton, Typography } from "@mui/material";
import { AudioVisualizer } from "react-audio-visualize";

const AudioPlayer = ({ audioBlob }) => {
  // --------------- Refs ---------------
  const audioRef = useRef();

  // --------------- States ---------------
  const [audioUrl, setAudioUrl] = useState("");
  const [duration, setDuration] = useState("00:00");
  const [isPlaying, setPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [audioBarWidth, setAudioBarWidth] = useState(200);

  // --------------- Effects ---------------

  useEffect(() => {
    // set url for controlling
    if (!audioBlob) {
      return;
    }
    const url = URL.createObjectURL(audioBlob);
    setAudioUrl(url);
    audioRef.current?.addEventListener("ended", () => {
      setCurrentTime(0);
      setPlaying(false);
    });
    audioRef.current?.addEventListener("timeupdate", () =>
      setCurrentTime(audioRef.current?.currentTime)
    );

    const audioContext = new (window.AudioContext ||
      window.webkitAudioContext)();

    let durationSeconds = 0;
    const fileReader = new FileReader();
    fileReader.onload = function () {
      const arrayBuffer = this.result;
      try {
        // Decode the audio data
        audioContext.decodeAudioData(arrayBuffer, function (buffer) {
          // Get the duration of the audio file in seconds
          const durationInSeconds = buffer.duration;
          const durationMinutes = Math.floor(durationInSeconds / 60)
            .toString()
            .padStart(2, "0");
          durationSeconds = Math.floor(durationInSeconds % 60)
            .toString()
            .padStart(2, "0");

          setDuration(`${durationMinutes}:${durationSeconds}`);
        });
      } catch (err) {
        console.log("error happened", err);
      }
    };
    fileReader.readAsArrayBuffer(audioBlob);

    //set up audio bars width
    const windowWidth = window?.innerWidth;
    let _audioBarWidth = 200;
    if (windowWidth < 300) {
      _audioBarWidth = 100;
    } else if (windowWidth < 400) {
      _audioBarWidth = 0.5 * windowWidth;
    }

    setAudioBarWidth(
      Math.min(_audioBarWidth, Math.max(Math.round(audioBlob.size / 300), 100))
    );
  }, [audioBlob]);

  // --------------- Functions ---------------
  const playPauseToggle = () => {
    const audio = new Audio();
    audio.src = audioUrl;

    audio.addEventListener("canplay", () => {
      audio.play();
    });
  };

  // --------------- Render ---------------
  return (
    audioBlob && (
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item xs={3} md={4}>
          <Grid container>
            <Grid item xs={10}>
              <AudioVisualizer
                blob={audioBlob}
                width={audioBarWidth}
                height={30}
                barWidth={3}
                gap={2}
                currentTime={currentTime}
                barPlayedColor={"#FF7A00"}
                barColor={"#fefefe"}
                // style={{ width: "200px" }}
              />
              <Typography sx={{ fontSize: 12, fontWeight: 400 }}>
                {duration}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item sx={{ marginLeft: "10px", paddingRight: 0 }}>
          <IconButton size="small" color="warning" onClick={playPauseToggle}>
            {isPlaying ? (
              <PauseCircleOutline sx={{ fontSize: 45 }} />
            ) : (
              <PlayCircleOutline sx={{ fontSize: 45 }} />
            )}
          </IconButton>
        </Grid>
      </Grid>
    )
  );
};

export default AudioPlayer;
