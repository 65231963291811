import { IconButton } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";

const iconStyle = {
  width: { xs: "40px", md: "100px" },
  height: "auto",
  display: { xs: "flex", md: "none" },
};

function MenuToggler({ toggleDrawer, isOpen }) {
  return (
    <IconButton onClick={toggleDrawer}>
      {isOpen ? <CloseIcon sx={iconStyle} /> : <MenuIcon sx={iconStyle} />}
    </IconButton>
  );
}

export default MenuToggler;
