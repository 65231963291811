import {
  AuthenticatedRoute,
  isAuthenticated,
} from "../middlewares/AuthenticatedRoute";
import Login from "../components/LoginPage";
import Chatbot from "../components/Chatbot";
import { Navigate } from "react-router-dom";

export const routes = [
  {
    path: "/",
    element: <Chatbot />,
  },
];

// use the following logic for authentication
/*
export const routes = [
  {
    path: "/",
    element: isAuthenticated() ? <Chatbot /> : <Navigate to="/login" />,
  },
  {
    path: "/login",
    element: isAuthenticated() ? <Navigate to="/" /> : <Login />,
  },
];
*/
