import {
  TextField,
  Paper,
  Button,
  Grid,
  IconButton,
  Divider,
  InputAdornment,
} from "@mui/material";
import { useMediaQuery } from "@mui/material";

import { Send as SendButton } from "@mui/icons-material";
import { AudioRecorder, useAudioRecorder } from "react-audio-voice-recorder";
import { RecordButton } from "./VoiceInput";
import styles from "../styles/messageInput.module.css";

function MessageInput({
  input,
  ref,
  handleTextInputChange,
  handleToggleRecording,
  isVoiceRecording,
  handleSendMessage,
  isLoading,
}) {
  // --------------- Const ---------------
  const isSmallScreen = useMediaQuery("(max-width: 600px)");

  const recorderControls = useAudioRecorder(
    {
      noiseSuppression: true,
      echoCancellation: true,
    },
    (err) => console.table(err) // onNotAllowedOrFound
  );

  return (
    <Grid
      container
      justifyContent="center"
      textAlign="center"
      alignItems="center"
      component="form"
      gap={1}
      onSubmit={handleSendMessage}
      sx={{
        position: "sticky",
        bottom: 0,
        paddingY: 1,
        backgroundColor: "#F5F5F5",
      }}
    >
      {!recorderControls.isRecording && (
        <Grid item xs={11} md={9}>
          <TextField
            multiline={true}
            maxRows={3}
            onKeyDown={({ key, shiftKey }) =>
              // handling send by enter and new line with shift+enter
              key == "Enter" && !shiftKey && handleSendMessage()
            }
            size="medium"
            id="textInput"
            autoFocus={true}
            value={input.text}
            onChange={handleTextInputChange}
            placeholder={
              isLoading
                ? "يرجى الانتظار حتى تتلقى الرد."
                : "إبحث عن کتاب أو مؤلف أو قسم من نص الکتاب"
            }
            disabled={isLoading}
            fullWidth
            autoComplete="off"
            InputProps={{
              startAdornment: (
                <RecordButton toggleHandler={handleToggleRecording} />
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    type="submit"
                    sx={{
                      color: "white",
                      rotate: "180deg",
                      background: "#C35D23",
                      borderRadius: "12px",
                      padding: "10px",
                      "&:hover": {
                        background: "#8c3b0b",
                      },
                    }}
                  >
                    <SendButton />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      )}
      {/* <Grid item display={{ xs: "inline-block", sm: "none" }}>
        <RecordButton toggleHandler={handleToggleRecording} />
      </Grid> */}

      {/* <Grid item xs={1}> */}
      {/* <IconButton
          type="submit"
          sx={{
            color: "white",
            rotate: "-90deg",
            background: "#C35D23",
          }}
        >
          <SendButton />
        </IconButton> */}
      {/* </Grid> */}
    </Grid>
  );
}

export default MessageInput;
