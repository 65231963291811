import { Grid, Typography } from "@mui/material";
import { motion } from "framer-motion";

import icon1 from "../images/Vector1.png";
import icon2 from "../images/Vector2.png";
import icon3 from "../images/Vector3.png";
import icon4 from "../images/Vector1.png";
import icon5 from "../images/Vector2.png";
import icon6 from "../images/Vector3.png";

const data = [
  {
    title: "مجموعة بیانات غنیة",
    text: "یحتوي علی 23457 کتاب",
    icon: icon3,
  },
  {
    title: "تعتمد علی تکنولوجیا الذکاء الإصطناعي",
    text: "إستخدام نماذج اللغة المتقدمة في تصمیم الربوت دردشة",
    icon: icon2,
  },
  {
    title: "فهم قوي للغة العربیة",
    text: "الإمکانیة علی إجابة الأسئلة المعقدة",
    icon: icon3,
  },
  {
    title: "نظام توصیة الکتب",
    text: "إقتراح و توصیة الکتب علی أساس أذواق و إحتیاجات و إهتمامات المستخدمین",
    icon: icon6,
  },
  {
    title: "تاریخ النمذجة",
    text: "إستخدام تاریخ الدردشة للإجابة علی سؤال المستخدم",
    icon: icon5,
  },
  {
    title: "تحویل الصوت إلی نص",
    text: "تطویر أفضل خدمة لتحویل الصوت إلی نص باللغة العربیة",
    icon: icon6,
  },
];

function BlankPage() {
  return (
    <Grid
      container
      gap={8}
      marginTop={5}
      justifyContent="center"
      alignItems="center"
      textAlign="center"
      sx={{ height: "60%" }}
    >
      <Grid xs={12}>
        <Typography
          component="h1"
          color="#212121"
          fontSize={48}
          fontWeight={700}
          marginBottom={1}
        >
          مرحبا بكم في{" "}
          <span
            style={{
              background: `linear-gradient(-90deg, #eb8e59, #F5F5F5)`,
              // WebkitBackgroundClip: "text",
              // color: "transparent",
              // padding: "2px",
              display: "inline-block",
            }}
          >
            ذکیتاب
          </span>
        </Typography>
        <Typography component="subtitle2" color="#C1C4C9" marginBottom={1}>
          إني لا أعلم شجرة أطول عمرا و لا أطیب عمرا و أقرب مجتنی کتاب
        </Typography>
      </Grid>
      {data.map((item, idx) => (
        <Grid key={idx} item xs={12} md={3}>
          <motion.div
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: (idx % 3) * 0.1 }}
          >
            <img src={item["icon"]} style={{ marginBottom: "15px" }} />
            <Typography
              component="h4"
              color="#212121"
              fontSize={20}
              fontWeight={500}
              marginBottom={1}
            >
              {item["title"]}
            </Typography>
            <Typography component="p" color="#212121" fontWeight={300}>
              {item["text"]}
            </Typography>
          </motion.div>
        </Grid>
      ))}
    </Grid>
  );
}

export default BlankPage;
