import { Button } from "@mui/material";
import homeIcon from "../../images/home.png";

function HomeButton({ size = "small" }) {
  return (
    <Button
      href="https://zakitab.com"
      target="_blank"
      variant="contained"
      color="PrimaryLight"
      size={size}
      sx={{
        borderRadius: 45,
        paddingX: 3,
        paddingY: 1,
        border: "1px solid #E2E8F0",
      }}
      startIcon={<img src={homeIcon} />}
    >
      ذکیتاب
    </Button>
  );
}

export default HomeButton;
