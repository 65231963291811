import { Grid, Button, Typography } from "@mui/material";
import { useMediaQuery } from "@mui/material";

import MenuToggler from "./MenuToggler";
import HomeButton from "./HomeButton";

function Header2({ toggleDrawer, isDrawerOpen }) {
  const isSmallScreen = useMediaQuery("(max-width: 900px)");

  return (
    <Grid
      container
      justifyContent="space-between"
      sx={{
        position: "sticky",
        paddingY: "2px",
        backgroundColor: "#F5F5F5",
      }}
    >
      {isSmallScreen && (
        <Grid item xs={1}>
          <MenuToggler toggleDrawer={toggleDrawer} isOpen={isDrawerOpen} />
        </Grid>
      )}
      <Grid item>
        <Typography
          component="h1"
          color="#1B2559"
          fontWeight={700}
          fontSize={32}
        >
          روبوت الدردشة
        </Typography>
      </Grid>
      {!isSmallScreen && (
        <Grid item>
          <HomeButton size={isSmallScreen ? "small" : "large"} />
        </Grid>
      )}
    </Grid>
  );
}

export default Header2;
