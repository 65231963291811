import { ListItem, ListItemIcon, Avatar } from "@mui/material";
import userIcon from "../../images/user.png";

function UserAvatar({ isMe = false, icon = userIcon }) {
  // if (isMe) {
  return (
    <ListItem sx={{ paddingX: 0 }}>
      <ListItemIcon sx={{ margin: 0 }}>
        <Avatar
          src={icon}
          alt="user"
          style={{
            marginLeft: isMe && "10px",
            marginRight: !isMe && "10px",
            objectFit: "fill",
          }}
        />
      </ListItemIcon>
    </ListItem>
  );
}

export default UserAvatar;
