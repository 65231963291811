import { Box, Grid, Typography } from "@mui/material";
import { useMediaQuery } from "@mui/material";

import MenuToggler from "./MenuToggler";

import chatbotBro from "../../images/chat-bot-bro.png";
import Logo from "../../images/logo.png";
import styles from "../../styles/sidebar.module.css";
import HomeButton from "./HomeButton";

function SideBar({ isDrawerOpen, toggleDrawer }) {
  const isSmallScreen = useMediaQuery("(max-width: 900px)");

  return (
    <Grid container justifyContent="center" alignItems="center">
      {isSmallScreen && (
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          marginTop={1}
          marginX={1}
        >
          <Grid item>
            <MenuToggler toggleDrawer={toggleDrawer} isOpen={isDrawerOpen} />
          </Grid>
          <Grid item>
            <HomeButton size="small" />
          </Grid>
        </Grid>
      )}
      <Grid item xs={12} textAlign="center" marginTop={3}>
        <Box component="img" src={Logo} />
      </Grid>

      {/* sidebar for larger screens */}
      <Grid item xs={10} marginTop={8} textAlign="center">
        <Box
          src={chatbotBro}
          component="img"
          sx={{
            maxWidth: {
              xs: "300px",
              md: "200px",
              lg: "280px",
              xl: "350px",
            },
          }}
          textAlign="center"
        />
      </Grid>
      <Grid item xs={10} marginTop={4}>
        <Typography component="p" color="#606060" textAlign="justify">
          <strong>ذکیتاب روبوت الدردشة</strong>
          <br /> روبوت دردشة ودود ، إنه على دراية وثنائي اللغة والذي يسمح لك
          بالعثور على كتبك المفضلة وتعلم موضوعات قيّمة عن عالَم الكتب .
        </Typography>
      </Grid>

      {/* sidebar for smaller screens */}
    </Grid>
  );
}

export default SideBar;
