import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
// import api from "../middlewares/api";
import { Alert, Grid, Paper, Snackbar } from "@mui/material";

// import Header from "./layout/Header";
import Header2 from "./layout/Header2";

import SideBar from "./layout/SideBar";
import ChatHistory from "./ChatHistory";
import MessageInput from "./MessageInput";

import styles from "../styles/chatbot.module.css";

// it is better to fetch this data according to user accessibility
import { botsData } from "../data/bots";

// import { res_test, sample_history } from "../mock/data";

const s2tAPI = process.env.REACT_APP_S2T;

const Chatbot = () => {
  // --------------- States ---------------
  const [errorMessage, setErrorMessage] = useState("");
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const [navbarBotID, setNavbarBotID] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isHistoryLoading, setIsHistoryLoading] = useState(false);
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState({
    text: "",
    audioUrl: "",
    isAudio: false,
  });

  const [isVoiceRecording, setIsVoiceRecording] = useState(false);

  const [isHeaderVisible, setHeaderIsVisible] = useState(true);

  // --------------- Effects ---------------
  useEffect(() => {
    let prevScrollPos = window.scrollY; // Use window.scrollY instead of pageYOffset
    window.addEventListener("scroll", () => {
      const currentScrollPos = window.scrollY; // Use window.scrollY
      setHeaderIsVisible(prevScrollPos > currentScrollPos);
      prevScrollPos = currentScrollPos;
    });

    // Cleanup the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", () => {});
    };
  }, []);

  useEffect(() => {
    // setMessages([]);
    axios
      .get(`${botsData[navbarBotID]["url"]}/reset-user`)
      .then((res) => {})
      .catch((err) => {
        console.log("reset user error", err);
      });
  }, [navbarBotID]);

  // useEffect(() => {
  //   if (!isLoading) {
  //     document.getElementById("textInput").focus();
  //   }
  // }, [isLoading, navbarBotID]);

  // --------------- Functions ---------------
  const errorBox = (err) => {
    console.log(err); //TODO: show proper message f
    setErrorMessage("حدث خطأ! حاول مرة اخرى");
    setTimeout(() => {
      setErrorMessage("");
    }, 3000);
  };

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };
  const botChangeHandler = (_, newValue) => {
    setIsHistoryLoading(true);
    setTimeout(() => {
      setIsHistoryLoading(false);
    }, 2000);

    setNavbarBotID(newValue);
  };

  const handleToggleRecording = async (blob) => {
    // set voice in message history
    setMessages([
      ...messages,
      { role: "user", isAudio: true, audioBlob: blob },
    ]);

    //use s2t to get text of message
    const res = await s2tVoiceMessage(blob);

    //send text message to chatbot api
    handleSendMessage(undefined, {
      role: "user",
      isAudio: true,
      audioBlob: blob,
      content: res,
    });
  };

  const handleTextInputChange = (event) => {
    setInput({
      isAudio: false,
      text: event.target.value,
      audioUrl: "",
      audioBlob: "",
    });
  };

  const s2tVoiceMessage = async (audioBlob) => {
    // Convert speech to text
    const audioFile = new File([audioBlob], "audio_file.wav", {
      type: "audio/webm",
    });
    const formData = new FormData();
    formData.append("file", audioFile);
    try {
      const { data } = await axios.post(s2tAPI, formData);
      return data.transcript;
    } catch (err) {
      errorBox(err);
      throw err;
    }
  };

  const handleSendMessage = async (e, userVoiceMessage = undefined) => {
    e?.preventDefault();

    if (!userVoiceMessage && input.text.trim() === "") return;
    setIsLoading(true);
    const isAudio = !!userVoiceMessage?.isAudio;
    const userQuery = isAudio ? userVoiceMessage.content : input.text;
    const newUserMessage = {
      isAudio: isAudio,
      content: userQuery,
      role: "user",
    };
    if (isAudio) {
      newUserMessage["audioBlob"] = userVoiceMessage["audioBlob"];
    }

    setMessages([...messages, newUserMessage]);
    setInput({
      text: "",
      audioUrl: "",
      isAudio: false,
    });

    const prevMessages = messages;
    let newContent = "";

    fetch(`${botsData[0]["url"]}/generate_answer_with_history_stream`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        query: userQuery,
        history: messages.map((message) => ({
          role: message.role,
          content: message.content,
        })),
      }),
    })
      .then((res) => {
        if (res.status === 500) {
          throw Error();
        }
        const reader = res.body.getReader();
        return new ReadableStream({
          start(controller) {
            return pump();
            function pump() {
              return reader.read().then(({ done, value }) => {
                if (done) {
                  setIsLoading(false);
                  controller.close();

                  return;
                }
                controller.enqueue(value);
                const text_decoder = new TextDecoder("utf-8");
                newContent += text_decoder.decode(value);

                setMessages([
                  ...prevMessages,
                  newUserMessage,
                  { role: "assistant", content: newContent },
                ]);
                return pump();
              });
            }
          },
        });
      })
      .catch((err) => {
        setIsLoading(false);
        setErrorMessage("حدث خطأ! حاول مرة اخرى.");
        setTimeout(() => {
          setErrorMessage("");
        }, 3000);
      });
  };

  // --------------- Render ---------------
  if (isDrawerOpen) {
    return (
      <Grid
        container
        justifyContent="center"
        //  className={styles.chatSection}
      >
        {/* sidebar content */}
        <Grid
          item
          xs={12}
          md={3}
          // sx={{ mx: "auto", display: { xs: "none", sm: "inline-block" } }}
          justifyContent="center"
        >
          <SideBar
            value={navbarBotID}
            setValue={botChangeHandler}
            navbarItems={botsData}
            isDrawerOpen={isDrawerOpen}
            toggleDrawer={toggleDrawer}
          />
        </Grid>
      </Grid>
    );
  }

  return (
    <div
      onClick={() => {
        setHeaderIsVisible(!isHeaderVisible);
      }}
    >
      {/* <Header ToggleMenu={toggleDrawer} isHeaderVisible={isHeaderVisible} /> */}

      {/* main section */}
      <Grid
        container
        justifyContent="center"
        className={styles.chatSection}
        padding={{ xs: 0, sm: "inherit" }}
      >
        {/* sidebar content */}
        <Grid
          item
          md={3}
          sx={{ mx: "auto", display: { xs: "none", md: "inline-block" } }}
          justifyContent="center"
        >
          <SideBar isDrawerOpen={isDrawerOpen} toggleDrawer={toggleDrawer} />
        </Grid>

        {/* chat-box content */}
        <Grid
          item
          xs={12}
          md={9}
          padding={{ sx: 0, sm: 2 }}
          sx={{
            margin: "0",
            backgroundColor: "#F5F5F5",
          }}
          height={{ sx: "110vh", md: "100vh" }}
        >
          <Header2 toggleDrawer={toggleDrawer} isDrawerOpen={isDrawerOpen} />
          <ChatHistory
            messages={messages}
            setMessages={setMessages}
            isLoading={isHistoryLoading}
          />

          <MessageInput
            input={input}
            handleTextInputChange={handleTextInputChange}
            handleToggleRecording={handleToggleRecording}
            isVoiceRecording={isVoiceRecording}
            handleSendMessage={handleSendMessage}
            isLoading={isLoading}
          />

          {/* <Divider /> */}
        </Grid>
        {errorMessage.length ? (
          <Snackbar
            open={errorMessage.length}
            autoHideDuration={2000}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <Alert severity="error">{errorMessage}</Alert>
          </Snackbar>
        ) : (
          <></>
        )}
      </Grid>
      {/* <Footer /> */}
    </div>
  );
};

export default Chatbot;
