import { useEffect } from "react";
import {
  Grid,
  List,
  ListItem,
  Box,
  LinearProgress,
  Typography,
  ListItemButton,
} from "@mui/material";
import ReactMarkdown from "react-markdown";

import UserAvatar from "./chatHistory/UserAvatar";
import BlankPage from "./BlankPage";
import AudioPlayer from "./AudioPlayer";

import robotIcon from "../images/botIcon.png";
import audioIcon from "../images/audio.svg";
import styles from "../styles/chatbot.module.css";

const ttsAPI = process.env.REACT_APP_T2S;

function ChatHistory({ messages, setMessages, isLoading }) {
  useEffect(() => {
    const elPage = document.getElementById("ChatHistory");
    elPage?.lastElementChild?.scrollIntoView({ behavior: "smooth" });
  }, [isLoading, messages]);

  const readMessageHandler = async (messageIdx, text) => {
    // if audio didn't exist, use t2s service to get audio and save on messages for future usage
    // otherwise play existing audio

    let url = messages[messageIdx]["audioUrl"];

    if (!url) {
      try {
        const res = await fetch(ttsAPI, {
          method: "POST",
          body: JSON.stringify({ text }),
          headers: {
            "Content-Type": "application/json",
            // Accept: "audio/m4a",
          },
        });

        const audioBlob = await res.blob();
        url = URL.createObjectURL(audioBlob);
      } catch (e) {
        console.log(e);
      }
    }
    setMessages((prevMessages) => {
      const _newMessages = [...prevMessages];
      _newMessages[messageIdx] = {
        ..._newMessages[messageIdx],
        audioUrl: url,
      };
      return _newMessages;
    });
    // set up and play audio
    const audio = new Audio();

    audio.src = url;

    audio.addEventListener("canplay", () => {
      audio.play();
    });

    // Handle errors
    audio.addEventListener("error", (error) => {
      console.error("Error loading audio:", error);
    });
  };

  return (
    <Grid className={styles.messageArea}>
      {messages.length === 0 ? (
        <BlankPage />
      ) : (
        <List className={styles.chatBubble} id="ChatHistory">
          {isLoading ? (
            <Box>
              <LinearProgress />
            </Box>
          ) : (
            messages.map((message, index) => (
              <Grid
                sx={{ marginBottom: "30px" }}
                container
                key={index}
                justifyContent={
                  message.role === "user" ? "flex-start" : "flex-end"
                }
                alignItems="center"
              >
                <Grid
                  item
                  display={{
                    xs: message.role === "user" ? "none" : "inherit",
                    sm: "inherit",
                  }}
                >
                  <List dense>
                    {message.role === "user" ? (
                      <UserAvatar isMe />
                    ) : (
                      <ListItemButton
                        sx={{ paddingX: 1 }}
                        size="small"
                        onClick={() =>
                          readMessageHandler(index, message.content)
                        }
                      >
                        <img src={audioIcon} alt="play" />
                      </ListItemButton>
                    )}
                  </List>
                </Grid>
                <Grid item maxWidth={{ sx: "100vw", sm: "65vw", md: "50vw" }}>
                  <ListItem
                    className={[
                      message.role === "user"
                        ? styles.chatBubbleUser
                        : styles.chatBubbleBot,
                      message.isAudio && styles.chatAudioBubble,
                    ].join(" ")}
                  >
                    <Grid item xs={12}>
                      <Grid
                        container
                        direction="column"
                        // maxWidth={{ xs: "200px", sm: "350px", md: "800px" }}
                      >
                        {message.isAudio ? (
                          <Grid item xs={12}>
                            {/* <audio controls src={message.audioUrl}></audio> */}
                            <AudioPlayer audioBlob={message.audioBlob} />
                          </Grid>
                        ) : (
                          <Grid item xs={12}>
                            <Typography
                              variant="body2"
                              style={{
                                whiteSpace: "pre-line",
                                fontWeight: 500,
                                fontSize: 14,
                                paddingBottom: "8px",
                                lineHeight: "30px",
                                paddingTop: 0,
                                maxWidth: "100%", // Adjusted maxWidth property
                                wordBreak: "break-word", // Added word-break property
                              }}
                            >
                              <ReactMarkdown
                                components={{
                                  p: ({ node, ...props }) => (
                                    <p style={{ margin: 0 }} {...props} />
                                  ),
                                }}
                              >
                                {message.content.trim()}
                              </ReactMarkdown>
                            </Typography>
                            <Grid container spacing={1}></Grid>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  </ListItem>
                </Grid>

                <Grid item display={{ xs: "none", sm: "inherit" }}>
                  {message.role === "assistant" && (
                    <List dense>
                      <UserAvatar icon={robotIcon} />
                    </List>
                  )}
                </Grid>
                {/* {message.role === "assistant" && (
                  <Grid item display={{ xs: "inherit", sm: "none" }}>
                    <List dense>
                      <ListItemButton
                        sx={{ paddingX: 1 }}
                        size="small"
                        onClick={() => readMessageHandler(message.content)}
                      >
                        <img src={audioIcon} />
                      </ListItemButton>
                    </List>
                  </Grid>
                )} */}
              </Grid>
            ))
          )}
        </List>
      )}
    </Grid>
  );
}

export default ChatHistory;
